<template>
 <StatisticsCalendar />
</template>

<script>
import StatisticsCalendar from "@/components/admin/statistics/StatisticsCalendar";
export default {
  name: "StatisticsView",
  components: {StatisticsCalendar}
}
</script>

<style scoped>

</style>
