<template>
  <v-container>
    <v-card>
      <v-card-title>Statistik</v-card-title>
      <v-card-text>
        <v-card outlined>
          <v-card-title>Zeitraum</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" lg="4">  <v-menu
                  v-model="consultationDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      v-model="dateRangeText"
                      :label="inputLables['dateFromTo']"
                      :rules="timeRules"
                      clearable
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    first-day-of-week="1"
                    v-model="createDate"
                    color="primary"
                    range
                ></v-date-picker>
              </v-menu>
              </v-col>
              <v-col cols="auto"> <v-btn :class="$vuetify.breakpoint.mdAndDown ? '' : 'mt-3'" color="primary" @click="allTime()">Gesamt</v-btn></v-col>
              <v-col cols="auto"> <v-btn :class="$vuetify.breakpoint.mdAndDown ? '' : 'mt-3'" color="primary" @click="selectTime('thisWeek')">Diese Woche</v-btn></v-col>
              <v-col cols="auto"> <v-btn :class="$vuetify.breakpoint.mdAndDown ? '' : 'mt-3'" color="primary" @click="selectTime('lastWeek')">Letzte Woche</v-btn></v-col>
              <v-col cols="auto"> <v-btn :class="$vuetify.breakpoint.mdAndDown ? '' : 'mt-3'" color="primary" @click="selectTime('thisMonth')">Diesen Monat</v-btn></v-col>
              <v-col cols="auto"> <v-btn :class="$vuetify.breakpoint.mdAndDown ? '' : 'mt-3'" color="primary" @click="selectTime('lastMonth')">Letzten Monat</v-btn></v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-text>
        <v-card outlined>
          <v-card-title>Versicherungsfilter</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4">
                <v-autocomplete
                    multiple
                    v-model="selectedAdvocardBusinessDataTypes"
                    label="Advocard Business"
                    :items="advocardBusinessDataTypes"
                    chips
                    small-chips
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <v-autocomplete
                    multiple
                    v-model="selectedAdvocardInternationalDataTypes"
                    label="Advocard International"
                    :items="advocardInternationalDataTypes"
                    chips
                    small-chips
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <v-autocomplete
                    multiple
                    v-model="selectedAdvocardPrivateDataTypes"
                    label="Advocard Private"
                    :items="advocardPrivateDataTypes"
                    chips
                    small-chips
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="8">
                <v-autocomplete
                    multiple
                    v-model="selectedAragDataTypes"
                    label="ARAG"
                    :items="aragDataTypes"
                    chips
                    small-chips
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="1">
                <v-checkbox
                    v-model="mpuData"
                    label="MPU"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="12" md="auto">  <v-btn color="primary" @click="selectAll()">Alle auswählen</v-btn></v-col>
              <v-col cols="12" md="auto"> <v-btn color="primary" @click="unselectAll()">Keine auswählen</v-btn></v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-text>
        <v-card outlined>
          <v-card-title>Anwaltsfilter</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="auto">
                <v-checkbox v-model="isLawyerFilterActive" label="Anwaltsfilter aktivieren"></v-checkbox>
              </v-col>
              <v-col cols="12" md="auto">
                <!--  TODO: Fix that there is standing "Anwalt" and not "Anwälte" -->
                <v-autocomplete
                    v-model="creator"
                    :items="creators"
                    :label="inputLables['creator']"
                    :placeholder="inputLables['creatorSearch']"
                    :search-input.sync="searchCreator"
                    clearable
                    color="primary"
                    multiple
                    hide-no-data
                    item-text="username"
                    item-value="username"
                    return-object
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="12" md="auto">
                <!-- TODO: Fix this - before set exec get cuz otherwise without typing nothing happens here -->
                <v-btn color="primary" @click="creator = creators">Alle auswählen</v-btn>
              </v-col>
              <v-col cols="12" md="auto">
                <v-btn color="primary" @click="creator = undefined">Keine auswählen</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-col cols="auto"> <v-btn :disabled="isLoading" :loading="isLoading" class="ml-2" color="primary" @click="updateCalendarButton()">Ausgewählten Zeitraum laden</v-btn></v-col>
      </v-card-actions>
      <v-divider></v-divider>
      <v-card-title>
        Gesamtwerte
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="auto">
            <v-chip :disabled="isLoading" color="primary" > <v-icon class="mr-1">mdi-weather-sunny</v-icon> 8 Uhr - 20 Uhr: {{ totalValues.dayTimeCount }} </v-chip>
          </v-col>
          <v-col cols="12" md="auto">
            <v-chip :disabled="isLoading" color="primary" > <v-icon class="mr-1">mdi-weather-night</v-icon> 20 Uhr - 8 Uhr: {{ totalValues.nightTimeCount }} </v-chip>
          </v-col>
          <v-col cols="12" md="auto">
          <v-chip :disabled="isLoading" color="primary" > <v-icon class="mr-1">mdi-hours-24</v-icon> 24 Uhr - 0 Uhr: {{ totalValues.totalCount }} </v-chip>
        </v-col>
        <v-col cols="12" md="auto">
          <v-chip v-if="totalValues.bestDay" :disabled="isLoading" color="primary" > <v-icon class="mr-1">mdi-arrow-expand-up</v-icon> {{ getGermanWeekdayName(totalValues.bestDay.day) + " mit " + totalValues.bestDay.count  + " " + $tc('statistic.case', totalValues.bestDay.count) }}</v-chip>
        </v-col>
        <v-col cols="12" md="auto">
          <v-chip v-if="totalValues.worstDay" :disabled="isLoading" color="primary" > <v-icon class="mr-1">mdi-arrow-collapse-down</v-icon>  {{ getGermanWeekdayName(totalValues.worstDay.day) + " mit " + (totalValues.worstDay.count === 999999999 ? 0 : totalValues.worstDay.count) + " " + $tc('statistic.case', totalValues.worstDay.count) }} </v-chip>
        </v-col>
        </v-row>

        <v-row>
        <v-spacer></v-spacer>
        <v-col  cols="12" md="auto">
          <v-btn @click="switchPercentageCalculationStyle()" color="secondary">Farbkalkulation:  <strong style="color: black">{{ percentageCalculationStyle === "week" ? " Wochenbasiert" : " Tagesbasiert"  }}</strong></v-btn>
        </v-col>
      <v-col  cols="12" md="auto">
        <v-btn :disabled="type === 'week'" color="primary" @click="weekSwitch()">Woche</v-btn>
      </v-col>
        </v-row>

      </v-card-text>
      <v-menu
          v-model="selectedOpen"
          :activator="selectedElement"
          :close-on-content-click="false"
          offset-x
      >
        <v-card flat min-width="350px">
          <v-toolbar :color="selectedEvent.color">
            <v-toolbar-title :style="shouldTextBeBlackBasedOnColor(selectedEvent.color) ? 'color: white' : 'color: black'">
              {{ selectedEvent.name  + " " + $tc('statistic.caseNormal', selectedEvent.name) }}
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field v-if="selectedEvent.dayOfWeek"
                                    :value="getGermanWeekdayName(selectedEvent.dayOfWeek)"
                                    disabled prepend-icon="mdi-calendar-arrow-right" readonly>
              </v-text-field>
              </v-col>
              <v-col>
                <v-text-field v-if="selectedEvent.dayOfWeek"
                                    :value="selectedEvent.start.substr(11, 5) + ' Uhr bis ' + selectedEvent.end.substr(11, 5) + ' Uhr'"
                                    disabled prepend-icon="mdi-clock-outline" readonly>
              </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <div v-for="(dataTypes, insurance) in filteredInsurances" :key="insurance" cols="12">
            <v-card-subtitle>{{ insurance }}</v-card-subtitle>
            <v-card-text>
              <v-row>
                <v-col v-for="(count, dataType) in dataTypes" :key="dataType" cols="auto" >
                  <v-chip color="primary" text-color="white">{{ dataType }}: {{ count }}</v-chip>
                </v-col>
              </v-row>
            </v-card-text>
          </div>
          <v-expansion-panels v-if="selectedEvent.name > 0">
            <v-expansion-panel>
              <v-expansion-panel-header>Statistik Details</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="auto">
                    <v-text-field label="Durchschnitt" disabled v-if="selectedEvent.average" v-model="selectedEvent.average"></v-text-field>
                  </v-col>
                  <v-col cols="auto">
                    <v-text-field label="% / Day" disabled v-if="selectedEvent.percentageOfDay" v-model="selectedEvent.percentageOfDay"></v-text-field>
                  </v-col>
                  <v-col cols="auto">
                    <v-text-field label="% / Woche" disabled v-if="selectedEvent.percentageOfWeek" v-model="selectedEvent.percentageOfWeek"></v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="selectedOpen = false">
              schliessen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <v-card-text>
        <v-progress-linear
            v-if="isLoading"
            color="primary"
            class="mb-4 mt-1"
            indeterminate
        ></v-progress-linear>
        <v-calendar
            ref="statisticsCalendar"
            :event-color="getEventColor"
            v-model="focus"
            :type="type"
            :weekdays="[1, 2, 3, 4, 5, 6, 0]"
            start="1999-06-07"
            :events="heatmapEvents"
            @click:date="viewDay"
            @click:more="viewDay"
            @click:event="showEvent"
            @change="updateRange"
        >
          <template style="  border: 0 solid white !important" v-slot:event="{ event }">
            <div class="ml-2" :style="shouldTextBeBlackBasedOnColor(event.color) ? 'color: white' : 'color: black'">
              <div v-if="event.name !== 0">
                <h3>{{ event.name }} {{ $tc('statistic.caseNormal', event.name) }}</h3>
                <!--
                <strong v-if="insuranceAndDataTypeWithMostCases(event).maxDataType">
                  {{ shortenInsuranceName(insuranceAndDataTypeWithMostCases(event).maxInsurance) }} - {{ insuranceAndDataTypeWithMostCases(event).maxDataType }}
                </strong>
                <span v-if="insuranceAndDataTypeWithMostCases(event).maxCount">
                ({{ insuranceAndDataTypeWithMostCases(event).maxCount }} {{ $tc('statistic.caseNormal', insuranceAndDataTypeWithMostCases(event).maxCount) }})
            </span>
            -->
              </div>
            </div>
          </template>
        </v-calendar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {sessionHandler} from "@/request/sessionHandler";
import {getLawyersURL, getStatisticsDataTypeURL, getStatisticsURL, requestMode} from "@/configBuilder";
import {showAlert} from "@/utils/alertHandler";
import i18n from "@/plugins/i18n";
import {permissionConst} from "@/plugins/permission";
import dateFormatter, {
  addMonths,
  addWeeks,
  getFirstDateOfMonthByDate, getLastDateOfMonthByDate,
  getMondayOfDate,
  getSundayOfDate
} from "@/utils/dateFormatter";

export default {
  name: "StatisticsCalendar",
  data: () => ({
    selectedAdvocardBusinessDataTypes: [],
    selectedAdvocardInternationalDataTypes: [],
    selectedAdvocardPrivateDataTypes: [],
    selectedAragDataTypes: [],

    advocardBusinessDataTypes: [],
    advocardInternationalDataTypes: [],
    advocardPrivateDataTypes: [],
    aragDataTypes: [],

    totalValues: {minPercentageOfDay: 0, minPercentageOfWeek: 0, maxPercentageOfDay: 0, maxPercentageOfWeek: 0, totalCount: 0, worstDay: {day: "MONDAY", count: 0, percentageNight: 0, percentageDay: 0}, bestDay: {day: "MONDAY", count: 0, percentageNight: 0, percentageDay: 0}, dayTimeCount: 0, nightTimeCount: 0, totalDayPercentage: 0, totalNightPercentage: 0},

    mpuData: false,
    percentageCalculationStyle: 'week',
    type: 'week',
    focus: '',
    isLoading: false,
    heatmapEvents: [],
    responseWithoutDataTypes: [],
    selectedEvent: {},
    selectedElement: null,
    colorRanges: [
      { color: '#004726', limit: 1 },
      { color: '#006836', limit: 0.8 },
      { color: '#008946', limit: 0.7 },
      { color: '#00AA56', limit: 0.6 },
      { color: '#00C466', limit: 0.5 },
      { color: '#23CE7F', limit: 0.4 },
      { color: '#4FD898', limit: 0.3 },
      { color: '#7BE2B1', limit: 0.2 },
      { color: '#A7EBCA', limit: 0.1 },
    ],
    selectedOpen: false,
    weekday: [
      {name: "MONDAY", date: "1999-06-07", ger: 'Montag'},
      {name: "TUESDAY", date: "1999-06-08", ger: 'Dienstag'},
      {name: "WEDNESDAY", date: "1999-06-09", ger: 'Mittwoch'},
      {name: "THURSDAY", date: "1999-06-10", ger: 'Donnerstag'},
      {name: "FRIDAY", date: "1999-06-11", ger: 'Freitag'},
      {name: "SATURDAY", date: "1999-06-12", ger: 'Samstag'},
      {name: "SUNDAY", date: "1999-06-13", ger: 'Sonntag'},
    ],

    //date selector
    createDate: [getMondayOfDate(new Date()).toISOString().substr(0, 10), getSundayOfDate(new Date()).toISOString().substr(0, 10)],
    consultationDateMenu: false,

    // creator
    isLawyerFilterActive: false,
    creator: undefined,
    creators: [],
    searchCreator: null,
  }),
  mounted() {
    this.type = this.$vuetify.breakpoint.mdAndDown ? 'day' : 'week'
    // not sure why this is here this.$refs.statisticsCalendar.checkChange();
    this.generatePermissions();
    this.getAllCreators();
  },
  methods: {

    selectTime(range) {
      let cD;
      switch (range) {
        case "thisWeek":
          cD = [getMondayOfDate(new Date()).toISOString().substr(0, 10), getSundayOfDate(new Date()).toISOString().substr(0, 10)]
          break;
        case "lastWeek":
          cD = [getMondayOfDate(addWeeks(new Date(), -1)).toISOString().substr(0, 10), getSundayOfDate(addWeeks(new Date(), -1)).toISOString().substr(0, 10)]
          break;
        case "thisMonth":
          cD = [getFirstDateOfMonthByDate(new Date()).toISOString().substr(0, 10), getLastDateOfMonthByDate(new Date()).toISOString().substr(0, 10)]
          break;
        case "lastMonth":
          cD = [getFirstDateOfMonthByDate(addMonths(new Date(), -1)).toISOString().substr(0, 10), getLastDateOfMonthByDate(addMonths(new Date(), -1)).toISOString().substr(0, 10)]
          break;
      }
      this.createDate = cD;
    },
    getGermanWeekdayName(day) {
      if (!this.weekday.find(weekday => weekday.name === day)) return "";
      return this.weekday.find(weekday => weekday.name === day).ger;
    },
    shouldTextBeBlackBasedOnColor(color) {
      const lightColors = this.colorRanges.slice(0, 6).map(color => color.color);
      return lightColors.includes(color);
    },
    async switchPercentageCalculationStyle() {
      this.percentageCalculationStyle = this.percentageCalculationStyle === 'week' ? 'day' : 'week';
      await this.updateRange()
    },
    allTime() {
      this.createDate = ["2020-01-01", new Date().toISOString().substr(0, 10)];
    },
    unselectAll() {
      this.selectedAdvocardBusinessDataTypes = [];
      this.selectedAdvocardInternationalDataTypes = [];
      this.selectedAdvocardPrivateDataTypes = [];
      this.selectedAragDataTypes = [];
      this.mpuData = false;
    },
    selectAll(){
      this.generatePermissions();
    },
     async updateCalendarButton() {
       await this.updateRange()
    },
    generatePermissions() {
          this.advocardBusinessDataTypes = permissionConst[1].childPermissions.map(perm => perm.value.split("/")[1]);
          this.advocardInternationalDataTypes = permissionConst[2].childPermissions.map(perm => perm.value.split("/")[1]);
          this.advocardPrivateDataTypes = permissionConst[0].childPermissions.map(perm => perm.value.split("/")[1]);
          this.aragDataTypes = permissionConst[3].childPermissions.map(perm => perm.value.split("/")[1]);

          // default selection is all
          // this.selectedAdvocardBusinessDataTypes = this.advocardBusinessDataTypes;
          // this.selectedAdvocardInternationalDataTypes = this.advocardInternationalDataTypes;
          // this.selectedAdvocardPrivateDataTypes = this.advocardPrivateDataTypes;
          // this.selectedAragDataTypes = this.aragDataTypes;
    },
    fillEmptyDaysWithEmptyEvents(data) {
      let allDays = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];
      Object.entries(data).forEach(([dataType, typeData]) => {
        Object.entries(typeData).forEach(([eventType, eventData]) => {
          let foundDays = Object.keys(eventData);
          let notFoundDays = allDays.filter(day => !foundDays.includes(day));
          notFoundDays.forEach(day => {
            if (!data[dataType][eventType][day]) {
              data[dataType][eventType][day] = {};
            }
          });
        });
      });
    },
    getTotalValues(data){
      let totalValues = {minPercentageOfDay: 0, minPercentageOfWeek: 0, maxPercentageOfDay: 0, maxPercentageOfWeek: 0, totalCount: 0, worstDay: {day: "", count: 999999999, percentageNight: 0, percentageDay: 0}, bestDay: {day: "", count: 0, percentageNight: 0, percentageDay: 0}, dayTimeCount: 0, nightTimeCount: 0, totalDayPercentage: 0, totalNightPercentage: 0}
      const dayTime = { start: 8, end: 19 }

      Object.entries(data).forEach((day) => {
        let dayData = day[1];
        let dayCount = 0
        let percentage = {day: 0, night: 0}
        Object.entries(dayData).forEach((hour) => {
          const time = hour[0];
          const hourData = hour[1];
          totalValues.totalCount += hourData.count
          // calculate dayTimeCount and nightTimeCount based on dayTime as time a limiter
          if (time >= dayTime.start && time <= dayTime.end ) {
            totalValues.dayTimeCount += hourData.count
            percentage.day += hourData.percentageOfWeek
          } else {
            totalValues.nightTimeCount += hourData.count
            percentage.night += hourData.percentageOfWeek
          }
          dayCount += hourData.count
          let percentageOfDay = hourData.percentageOfDay
          let percentageOfWeek = hourData.percentageOfWeek
          if(percentageOfDay < totalValues.minPercentageOfDay){
            totalValues.minPercentageOfDay = percentageOfDay
          }
          if(percentageOfDay > totalValues.maxPercentageOfDay){
            totalValues.maxPercentageOfDay = percentageOfDay
          }
          if(percentageOfWeek < totalValues.minPercentageOfWeek){
            totalValues.minPercentageOfWeek = percentageOfWeek
          }
          if(percentageOfWeek > totalValues.maxPercentageOfWeek){
            totalValues.maxPercentageOfWeek = percentageOfWeek
          }
        })
        if (dayCount < totalValues.worstDay.count){
          totalValues.worstDay = {day: day[0], count: dayCount, percentageNight: percentage.night, percentageDay: percentage.day}
        }
        if (dayCount > totalValues.bestDay.count){
          totalValues.bestDay = {day: day[0], count: dayCount, percentageNight: percentage.night, percentageDay: percentage.day}
        }
      })
      this.totalValues = totalValues
      return totalValues
    },
    calculateColorUsingOriginalData(originalData, dayName, time, minMaxValues) {
      let maxPercentage = 0;
      let totalPercentage = 0;

      Object.entries(originalData[dayName] || {}).forEach(([hour, appointment]) => {
        if (hour == time) {
          let percentageOf = this.percentageCalculationStyle === "week" ? appointment.percentageOfWeek : appointment.percentageOfDay;
          totalPercentage += percentageOf;
          if (percentageOf > maxPercentage) {
            maxPercentage = percentageOf;
          }
        }
      });

      const maxColorPercentage = this.percentageCalculationStyle === "week" ? minMaxValues.maxPercentageOfWeek : minMaxValues.maxPercentageOfDay;
      const colorValue = totalPercentage > maxColorPercentage ? maxColorPercentage : totalPercentage;

      return this.colorRanges.find((colorRange) => colorValue > maxColorPercentage * colorRange.limit)?.color || "#c2efdc";
    },
    async statisticDataWithDataType() {
      this.isLoading = true;
      await this.statisticData();
      await sessionHandler();
      this.$http
          .get(
              getStatisticsDataTypeURL(), {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
                params: {
                  'createdAtFrom': this.createDate[0],
                  'createdAtTo': this.createDate[1],
                  'createdBy': this.isLawyerFilterActive && this.creator ? this.creator.map(ct => ct.username).join() : null,
                  'advocardBusinessDataTypes': this.selectedAdvocardBusinessDataTypes.join(),
                  'advocardInternationalDataTypes': this.selectedAdvocardInternationalDataTypes.join(),
                  'advocardPrivateDataTypes': this.selectedAdvocardPrivateDataTypes.join(),
                  'aragDataTypes': this.selectedAragDataTypes.join(),
                  'mpuData': this.mpuData,
                }
              }
          )
          .then((response) => {
            this.heatmapEvents = [];
            let alltimes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
            let data = response.data;

            const totalValues = this.getTotalValues(this.responseWithoutDataTypes);
            this.fillEmptyDaysWithEmptyEvents(data);

            if (response.status === 200) {
              let aggregatedData = {};

              Object.entries(data).forEach(([insurance, typeData]) => {
                Object.entries(typeData).forEach(([eventType, eventData]) => {
                  Object.entries(eventData).forEach(([dayName, appointments]) => {
                    if (!aggregatedData[dayName]) {
                      aggregatedData[dayName] = {};
                    }

                    Object.entries(appointments).forEach(([time, appointment]) => {
                      if (!aggregatedData[dayName][time]) {
                        aggregatedData[dayName][time] = {
                          count: 0,
                          average: 0,
                          percentageOfDay: 0,
                          percentageOfWeek: 0,
                          dataTypes: {},
                          insurances: {}
                        };
                      }

                      let aggregatedAppointment = aggregatedData[dayName][time];
                      aggregatedAppointment.count += appointment.count;
                      aggregatedAppointment.average += appointment.average;
                      aggregatedAppointment.percentageOfDay += appointment.percentageOfDay;
                      aggregatedAppointment.percentageOfWeek += appointment.percentageOfWeek;

                      if (!aggregatedAppointment.dataTypes[eventType]) {
                        aggregatedAppointment.dataTypes[eventType] = 0;
                      }
                      aggregatedAppointment.dataTypes[eventType] += appointment.count;

                      if (!aggregatedAppointment.insurances[insurance]) {
                        aggregatedAppointment.insurances[insurance] = {};
                      }
                      if (!aggregatedAppointment.insurances[insurance][eventType]) {
                        aggregatedAppointment.insurances[insurance][eventType] = 0;
                      }
                      aggregatedAppointment.insurances[insurance][eventType] += appointment.count;
                    });
                  });
                });
              });

              Object.entries(aggregatedData).forEach(([dayName, appointments]) => {
                if (this.weekday.some(wd => wd.name === dayName)) {
                  let times = [];

                  Object.entries(appointments).forEach(([time, appointment]) => {
                    let endTime = parseInt(time) + 1;
                    let calculatedColor = this.calculateColorUsingOriginalData(this.responseWithoutDataTypes, dayName, time, totalValues);

                    let finalAppointment = {
                      start: this.weekday.filter(wd => wd.name === dayName)[0].date + " " + time + ":00",
                      end: this.weekday.filter(wd => wd.name === dayName)[0].date + " " + endTime + ":00",
                      name: appointment.count,
                      color: calculatedColor,
                      dayOfWeek: dayName,
                      dataTypes: appointment.dataTypes,
                      insurances: appointment.insurances
                    };
                    times.push(parseInt(time));
                    this.heatmapEvents.push({...finalAppointment, ...appointment});
                  });

                  let missingTimes = alltimes.filter(x => !times.includes(x));
                  missingTimes.forEach((time) => {
                    let endTime = parseInt(time) + 1;
                    let finalAppointment = {
                      start: this.weekday.filter(wd => wd.name === dayName)[0].date + " " + time + ":00",
                      end: this.weekday.filter(wd => wd.name === dayName)[0].date + " " + endTime + ":00",
                      name: 0,
                      color: "#D3F5E3",
                      dayOfWeek: dayName,
                    };
                    this.heatmapEvents.push(finalAppointment);
                  });
                }
              });
            } else {
              showAlert(i18n.t('error.api.undefined') + "Statistics-sD1", "error");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Statistics-sD2", "error");
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    async statisticData() {
      this.isLoading = true;
      await sessionHandler();
      return new Promise((resolve, reject) => {
        this.$http
            .get(
                getStatisticsURL(), {
                  mode: requestMode(),
                  headers: {
                    'Accept': 'application/json',
                    'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                  },
                  params: {
                    'createdAtFrom': this.createDate[0],
                    'createdAtTo': this.createDate[1],
                    'createdBy': this.isLawyerFilterActive && this.creator ? this.creator.map(ct => ct.username).join() : null,
                    'advocardBusinessDataTypes': this.selectedAdvocardBusinessDataTypes.join(),
                    'advocardInternationalDataTypes': this.selectedAdvocardInternationalDataTypes.join(),
                    'advocardPrivateDataTypes': this.selectedAdvocardPrivateDataTypes.join(),
                    'aragDataTypes': this.selectedAragDataTypes.join(),
                    'mpuData': this.mpuData,
                  }
                }
            )
            .then((response) => {
              if (response.status === 200) {
                this.responseWithoutDataTypes = response.data;
                resolve(response.data);
              } else {
                showAlert(i18n.t('error.api.undefined') + "Statistics-sD1", "error");
                reject();
              }
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.status === 401) {
                  showAlert(i18n.t('warning.unauthorised'), "warning");
                }
              } else {
                showAlert(i18n.t('error.api.undefined') + "Statistics-sD2", "error");
              }
              reject();
            })
      });
    },
    async updateRange() {
      this.hideDates()
      await this.statisticDataWithDataType()
    },
    // switch to week
    async weekSwitch() {
      this.type = 'week'
      await this.wait(25)
      this.hideDates()
    },
    // view day
    viewDay({date}) {
      this.focus = date
      this.type = 'day'
    },
    // get event color
    getEventColor(event) {
      return event.color
    },
    // shorten insurance name
    shortenInsuranceName(name) {
      if (name.includes("Advocard")) {
        if (name.includes("Private")) {
          return "AdvoPriv";
        } else if (name.includes("International")) {
          return "AdvoInt";
        } else if (name.includes("Business")) {
          return "AdvoBus";
        }
      }
      return name;
    },
    // open event details dialog
    showEvent({nativeEvent, event}) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => this.selectedOpen = true, 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    // get all lawyers
    async getAllCreators() {
      //  if Session isValid
      await sessionHandler();

      this.$http
          .get(
              getLawyersURL()
              , {
                mode: requestMode(),
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              this.creators = response.data
              if (this.creators.length === 0) {
                showAlert(i18n.t('info.noEntries'), "info");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Statistics-gAC1", "error");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Statistics-gAC2", "error");
            }
          })
          .finally(() => {
            this.isLoadingCreator = false;
          })
    },
    // hide dates
    hideDates() {
      for (let j = 1; j <= 7; j++) {
        try {
          this.$refs.statisticsCalendar.$children[0].$el.children[0].children[j].children[0].style.display = "none"
        } catch (e) {
          //not found
        }
      }
      this.$refs.statisticsCalendar.$children[0].$children.forEach((button) => {
        if (this.weekday.filter(wd => { return button.$el.children[0].innerHTML.includes(wd.ger) }).length === 0){
          const date = button.$el.children[0].innerHTML > 9 ? '1999-06-' + button.$el.children[0].innerHTML : '1999-06-0' + button.$el.children[0].innerHTML
          button.$el.children[0].innerHTML = this.weekday[this.weekday.findIndex(weekday => weekday.date === date)].ger
        }

        button.$el.classList.remove("v-btn--fab", "v-btn--round")
        button.$el.style.marginTop = "10px"
      })
    },
    // wait
    async wait(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
      insuranceAndDataTypeWithMostCases(event) {
        let maxDataType = '';
        let maxCount = 0;
        let maxInsurance = '';

        Object.entries(event.insurances).forEach(([insurance, dataTypes]) => {
          Object.entries(dataTypes).forEach(([dataType, count]) => {
            if (count > maxCount) {
              maxCount = count;
              maxDataType = dataType;
              maxInsurance = insurance;
            }
          });
        });

        return {
          maxDataType,
          maxInsurance,
          maxCount
        };
      }
  },
  computed: {
    errorMessage() {
      return JSON.parse(JSON.stringify(this.$t('error.validation')));
    },
    timeRules() {
      return [
        this.createDate.length === 2 || this.errorMessage["twoDatesNeeded"],
      ]
    },
    inputLables() {
      return JSON.parse(JSON.stringify(this.$t('export.admin.inputField')));
    },
      filteredInsurances() {
        if (!this.selectedEvent || !this.selectedEvent.insurances) {
          return {};
        }
        let filteredInsurances = {};
        Object.entries(this.selectedEvent.insurances).forEach(([insurance, dataTypes]) => {
          let filteredDataTypes = Object.fromEntries(
              Object.entries(dataTypes).filter(([, count]) => count > 0)
          );
          if (Object.keys(filteredDataTypes).length > 0) {
            filteredInsurances[insurance] = filteredDataTypes;
          }
        });
        return filteredInsurances;
      },
    dateRangeText: {
      get() {
        let formattedDates = [];
        this.createDate.forEach(date => {
          formattedDates.push(dateFormatter(date))
        })
        return formattedDates.length > 0 ? formattedDates.join(' bis ') : ''
      },
      set() {
        this.createDate = []
        let formattedDates = [];
        this.createDate.forEach(date => {
          formattedDates.push(dateFormatter(date))
        })
        return formattedDates.length > 0 ? formattedDates.join(' bis ') : ''
      }
    },
  },
  watch: {
    searchCreator() {
      // Items have already been loaded
      if (this.creators.length > 0) return

      // Items have already been requested
      if (this.isLoadingCreator) return

      this.isLoadingCreator = true

      // Lazily load input items
      this.getAllCreators();

    },
}
}
</script>

<style scoped lang="sass">
div .v-event-timed
  border: 0 solid white !important
</style>
